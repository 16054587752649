import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Helmet from "react-helmet";
import { MarkdownLayout } from '../../partials/MarkdownLayout';
import { Colors, Fonts } from "../../materials";
import { ImageStaticFluid, Label, LinkGatsby, Heading, Container, Box, LinkAnchor, Code, ButtonIcon, Input, List, ListItem, Note, Text, TextBold } from "../../elements";
import { SnippetNavigationLandmarksAriaLabel } from '../../partials/SnippetNavigationLandmarksAriaLabel';
import { SnippetBreadcrumbs } from '../../partials/SnippetBreadcrumbs';
import { SnippetBreadcrumbsMarkup } from '../../partials/SnippetBreadcrumbsMarkup';
import { SnippetNavigationLandmarksAriaLabelledby } from '../../partials/SnippetNavigationLandmarksAriaLabelledby';
import { SnippetAccordion } from '../../partials/SnippetAccordion';
import { SnippetAccordionFlowchart } from '../../partials/SnippetAccordionFlowchart';
export const _frontmatter = {
  "seoTitle": "Accessible Accordion - examples and best practices | Aditus",
  "seoURL": "https://www.aditus.io/patterns/accordion/",
  "seoImage": "https://www.aditus.io/social/patterns/accordion.png",
  "seoImageAlt": "Text reading 'accessible accordion' with the Aditus logo next to it",
  "title": "Accessible Accordion",
  "description": "Learn how to create accessible accordion components with practical examples and code samples.",
  "lead": ["Accordion widgets require some accessibility considerations to make them useful for everyone. In this article we explain what you need to do to create accessible, inclusive accordion components."],
  "toc": {
    "nodes": [{
      "selfId": "what-is-an-accordion",
      "selfText": "What it an accordion",
      "nodes": [{
        "selfId": "accordion-parts-header-and-panel",
        "selfText": "Header and Panel"
      }, {
        "selfId": "when-not-to-use-an-accordion",
        "selfText": "When not to use"
      }]
    }, {
      "selfId": "accordion-and-accessibility",
      "selfText": "Accessibility",
      "nodes": [{
        "selfId": "keyboard-support",
        "selfText": "Keyboard support"
      }, {
        "selfId": "aria-roles-states-and-properties",
        "selfText": "ARIA"
      }]
    }, {
      "selfId": "live-demo",
      "selfText": "Live Demo",
      "nodes": [{
        "selfId": "step-1-markup-structure",
        "selfText": "Markup"
      }, {
        "selfId": "step-2-relationships",
        "selfText": "Relationships"
      }, {
        "selfId": "step-3-state",
        "selfText": "State"
      }]
    }, {
      "selfId": "implementations-of-the-accordion-pattern",
      "selfText": "Implementations",
      "nodes": [{
        "selfId": "vanilla-js",
        "selfText": "Vanilla JS"
      }, {
        "selfId": "react",
        "selfText": "React"
      }, {
        "selfId": "vue-js",
        "selfText": "Vue.js"
      }]
    }, {
      "selfId": "related-wcag-success-criterion",
      "selfText": "Related SC"
    }]
  }
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <MarkdownLayout {...props} renderAfter={() => <>
      <nav aria-labelledby='related-topics'>
      <Box mt={6} />
      <Box bg={Colors.oc.gray[0]} py={8}>
        <Container maxWidth='l'>
          <Heading id='related-topics'>Related Topics</Heading>
          <Box mt={6} />
          <List display='flex' flexWrap='wrap' m={-4}>
            <ListItem p={4} width={[1, 1 / 2, 1 / 3]}>
              <LinkGatsby to='/patterns/breadcrumbs/' css={{
              display: 'block',
              textDecoration: 'none',
              '& > *': {
                backgroundColor: Colors.oc.gray[2],
                border: `5px solid ${Colors.oc.gray[3]}`
              },
              '&:hover > *': {
                backgroundColor: Colors.oc.blue[0],
                border: `5px solid ${Colors.oc.blue[3]}`
              }
            }}>
                <Box display='flex' alignItems='center' justifyContent='center' px={3} py={3} height={180}>
                  <Box display='flex' flexDirection='column'>
                    <Label as='span'>Patterns</Label>
                    <Text as='span' fontSize={5} css={{
                    textDecoration: 'underline'
                  }}>
                      Breadcrumbs
                    </Text>
                  </Box>
                </Box>
              </LinkGatsby>
            </ListItem>
            <ListItem p={4} width={[1, 1 / 2, 1 / 3]}>
              <LinkGatsby to='/patterns/multiple-navigation-landmarks/' css={{
              display: 'block',
              textDecoration: 'none',
              '& > *': {
                backgroundColor: Colors.oc.gray[2],
                border: `5px solid ${Colors.oc.gray[3]}`
              },
              '&:hover > *': {
                backgroundColor: Colors.oc.blue[0],
                border: `5px solid ${Colors.oc.blue[3]}`
              }
            }}>
                <Box display='flex' alignItems='center' justifyContent='center' px={3} py={3} height={180}>
                  <Box display='flex' flexDirection='column'>
                    <Label as='span'>Patterns</Label>
                    <Text as='span' fontSize={5} css={{
                    textDecoration: 'underline'
                  }}>
                      Multiple <code>{`<nav>`}</code>
                    </Text>
                  </Box>
                </Box>
              </LinkGatsby>
            </ListItem>
          </List>
        </Container>
      </Box> 
    </nav>
    </>} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`What is an accordion`}</h2>
    <Box mt={4} mdxType="Box" />
    <p>{`Accordions are a common UX pattern that can help reduce the amount of information
presented to users. You might recognise this pattern by other names, such as
collapsible sections, show/hide content, among others.`}</p>
    <p>{`Accordions relate to the UX technique of `}<LinkAnchor href="https://en.wikipedia.org/wiki/Progressive_disclosure" target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">{`progressive disclosure`}</LinkAnchor>{`, which aims to maintain the focus of a user's attention by reducing clutter, confusion and cognitive workload.
It works by only present details of a section upon a tap or click, if necessary.`}</p>
    <p>{`Concerning its visual and interaction design, Smashing Magazine published a great
article on `}<LinkAnchor href="Designing The Perfect Accordion" target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">{`how to design the perfect accordion`}</LinkAnchor>{`.`}</p>
    <Box mt={8} mdxType="Box" />
    <Box display='flex' flexWrap='wrap' m={-3} mdxType="Box">
  <Box p={3} width={[1, 2 / 3, 1 / 2, 1 / 2]} mdxType="Box">
    <Box bg={Colors.oc.gray[1]} p={7} flex='1 1 auto' display='flex' alignItems='center' justifyContent='center' mdxType="Box"> 
      <Box maxWidth={271} width={1} mdxType="Box">
        <ImageStaticFluid path='illustrations/patterns/aria/accordion/accordion-section.png' alt='Illustration of sections of content' mdxType="ImageStaticFluid" />
      </Box>
    </Box>
    <Box mt={4} mdxType="Box" />
    <Text mdxType="Text">
      <Text as='span' fontWeight='700' mdxType="Text">⛔ No Accordion</Text>
      <br />
      <Text as='span' display='block' css={{
            minHeight: 50
          }} color={Colors.oc.gray[7]} mdxType="Text">Long content can overwhelm users.</Text>
    </Text>
    
  </Box>
  <Box p={3} width={[1, 2 / 3, 1 / 2, 1 / 2]} display='flex' flexDirection='column' mdxType="Box">
    <Box mt={6} display={['block', 'block', 'none']} mdxType="Box" />
    <Box bg={Colors.oc.gray[1]} p={7} flex='1 1 auto' display='flex' alignItems='center' justifyContent='center' mdxType="Box"> 
      <Box maxWidth={293} width={1} mdxType="Box">
      <ImageStaticFluid path='illustrations/patterns/aria/accordion/accordion-section-with.png' alt='Illustration of sections of content using the accordion pattern' mdxType="ImageStaticFluid" />
    </Box>
    </Box>
    <Box mt={4} mdxType="Box" />
    <Text mdxType="Text">
      <Text as='span' fontWeight='700' mdxType="Text">✅ With Accordion</Text>
      <br />
      <Text as='span' display='block' css={{
            minHeight: 50
          }} color={Colors.oc.gray[7]} mdxType="Text">The page is decluttered as it only shows content when users want to read it.</Text>
    </Text>
  </Box>
    </Box>
    <Box mt={8} mdxType="Box" />
    <h3>{`Accordion Parts: Header and Panel`}</h3>
    <Box mt={4} mdxType="Box" />
    <p>{`An accordion is a list of items, each composed with two parts: a `}<Text as='strong' fontWeight='700' color={Colors.tw.pink['700']} mdxType="Text">header</Text> and a <Text as='strong' fontWeight='700' color={Colors.oc.teal[9]} mdxType="Text">{`panel`}</Text>{`,
as shown in the figure below.`}</p>
    <Box mt={7} mdxType="Box" />
    <Box maxWidth={623} mx='auto' mdxType="Box">
  <ImageStaticFluid path='articles/patterns/accordion-header-panel.png' alt='Illustration of an accordion with its headers and panel labels.' mdxType="ImageStaticFluid" />
    </Box> 
    <Box mt={7} mdxType="Box" />
    <List display='flex' justifyContent='center' flexWrap={['wrap', 'wrap', 'nowrap']} m={-5} mdxType="List">
    <ListItem p={5} maxWidth='copy' mdxType="ListItem">
      <Box as='strong' color={Colors.tw.pink['700']} fontSize={5} mdxType="Box"> 
        Header
      </Box>
      <Text mdxType="Text">
        Summary of the section of content we want to hide/show. It
        also serves as the visibility control of the content (hint: <code>{`<button>`}</code>), meaning
        it is interactive with a mouse <strong>and</strong> keyboard.
      </Text>
      <Text mdxType="Text">
        It usually contains a title  describing the section, and a visual
        indicator of the visibility state of the accordion item.
      </Text>
    </ListItem>  
    <ListItem p={5} maxWidth='copy' mdxType="ListItem">
      <Box as='strong' color={Colors.oc.teal[9]} fontSize={5} mdxType="Box">
        Panel
      </Box>
      <Text mdxType="Text">The section of content we want to show/hide.</Text>
      <Text mdxType="Text">When the accordion is closed, the content inside the panel shouldn't be visible or interactable (e.g.: focusable elements shouldn't be focusable when they are not visible on screen).</Text>
    </ListItem>  
    </List>
    <Box mt={8} mdxType="Box" />
    <h3>{`When not to use an accordion`}</h3>
    <Box mt={4} mdxType="Box" />
    <p>{`Given its benefits, it can be tempting to use accordions whenever possible, but this can often do more harm than good.`}</p>
    <p>{`Nielsen Norman Group gives some criteria on `}<LinkAnchor href='https://www.nngroup.com/articles/accordions-complex-content/' mdxType="LinkAnchor">{`when to use accordions`}</LinkAnchor>{` and when to avoid using it.
In short, `}<TextBold as='q' cite="https://www.nngroup.com/articles/accordions-complex-content/" mdxType="TextBold">{`when all the page content is relevant to users, it is more advantageous to show all the content at once, even if doing so results in longer pages. On the desktop it is easier to simply scroll the page than to decide on which topics to click on`}</TextBold>{`.`}</p>
    <Box mt={8} mdxType="Box" />
    <h2>{`Accordion and Accessibility`}</h2>
    <Box mt={4} mdxType="Box" />
    <p>{`As HTML doesn't natively provide an accordion element, we need to implement this widget by
making sure it is accessible to keyboard and screen reader users. `}</p>
    <Box mt={4} mdxType="Box" />
    <p>{`That's what we will cover next. The implementation we will take as example comes from the `}<LinkAnchor href="https://www.w3.org/TR/wai-aria-practices-1.1/#aria_ex" target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">{`"Design Patterns and Widgets"`}</LinkAnchor>{` in the `}<LinkAnchor href="https://www.w3.org/TR/wai-aria-practices-1.2/" target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">{`WAI-ARIA Authoring Practices 1.2`}</LinkAnchor>{`.`}</p>
    <h3>{`Keyboard Support`}</h3>
    <Box mt={4} mdxType="Box" />
    <table aria-labelledby='keyboard-support'>
  <thead>
    <tr>
      <th>Element</th>
      <th>Key</th>
      <th>Required</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Element: </TextBold>
        <Text as='span' fontWeight='900' color={Colors.tw.pink['700']} mdxType="Text">header</Text>
      </Box>
      <td>
        <Box display='flex' alignItems='baseline' mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Key: </TextBold>
          <code>{`Enter`}</code>&nbsp;or&nbsp;<code>{`Space`}</code>
        </Box>
      </td>
      
      <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Required: </TextBold>
        <span role="img" aria-label="yes">✅</span>
      </Box>
      <td>
        Changes the collapsed/expanded state of the associated panel.
      </td>
    </tr>
    <tr>
      <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Element: </TextBold>
        <Text as='span' fontWeight='900' color={Colors.tw.pink['700']} mdxType="Text">header</Text>
      </Box>
      <td>
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Key: </TextBold>
        <code>{`Arrow Down`}</code>
      </td>
      
      <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Required: </TextBold>
        <span role="img" aria-label="no">✖️</span>
      </Box>
      <td>
        Moves focus to the <strong>next</strong> accordion header.
      </td>
    </tr>
    <tr>
      <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Element: </TextBold>
        <Text as='span' fontWeight='900' color={Colors.tw.pink['700']} mdxType="Text">header</Text>
      </Box>
      <td>
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Key: </TextBold>
        <code>{`Arrow Up`}</code>
      </td>
      
      <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Required: </TextBold>
        <span role="img" aria-label="no">✖️</span>
      </Box>
      <td>
        Moves focus to the <strong>previous</strong> accordion header.
      </td>
    </tr>
    <tr>
      <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Element: </TextBold>
        <Text as='span' fontWeight='900' color={Colors.tw.pink['700']} mdxType="Text">header</Text>
      </Box>
      <td>
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Key: </TextBold>
        <code>{`Home`}</code>
      </td>
      
      <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Required: </TextBold>
        <span role="img" aria-label="no">✖️</span>
      </Box>
      <td>
        Moves focus to the <strong>first</strong> accordion header.
      </td>
    </tr>
    <tr>
      <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Element: </TextBold>
        <Text as='span' fontWeight='900' color={Colors.tw.pink['700']} mdxType="Text">header</Text>
      </Box>
      <td>
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Key: </TextBold>
        <code>{`End`}</code>
      </td>
      
      <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Required: </TextBold>
        <span role="img" aria-label="no">✖️</span>
      </Box>
      <td>
        Moves focus to the <strong>last</strong> accordion header.
      </td>
    </tr>
  </tbody>
    </table>
    <h3>{`ARIA Roles, States and Properties`}</h3>
    <Box mt={4} mdxType="Box" />
    <table aria-labelledby='aria-roles-states-and-properties'>
  <thead>
    <tr>
      <th>Element</th>
      <th>Attribute</th>
      <th>Role</th>
      <th>Required</th>
      <th>Notes</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Element: </TextBold>
        <Text as='span' fontWeight='900' color={Colors.tw.pink['700']} mdxType="Text">header</Text>
      </Box>
      
      <td>
      </td>
      <td>
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Role: </TextBold>
        <code>{`button`}</code>
      </td>
      <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Required: </TextBold>
        <span role="img" aria-label="yes">✅</span>
      </Box>
      <td>
        The title of each accordion header is within a <code>{`<button />`}</code>.
      </td>
    </tr>
    <tr>
      <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Element: </TextBold>
        <Text as='span' fontWeight='900' color={Colors.tw.pink['700']} mdxType="Text">header</Text>
      </Box>
      
      
      <td>
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Attribute: </TextBold>
        <code className='whitespace-no-wrap'>{`aria-controls`}</code>
      </td>
      <td>
      </td>
      <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Required: </TextBold>
        <span role="img" aria-label="no">✖️</span>
      </Box>
      <td>
        Each button within an accordion header, is associated with its corresponding
        panel by using <code>{`aria-controls="<panel-id>"`}</code>
      </td>
    </tr>
    <tr>
      <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Element: </TextBold>
        <Text as='span' fontWeight='900' color={Colors.tw.pink['700']} mdxType="Text">header</Text>
      </Box>
      
      <td>
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Attribute: </TextBold>
        <code className='whitespace-no-wrap'>{`disabled`}</code>
      </td>
      <td>
        
      </td>
      <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Required: </TextBold>
        <span role="img" aria-label="no">✖️</span>
      </Box>
      <td>
        If a panel can't be collapsed (e.g.: at least one panel needs to be open),
        that is indicated using the <code>{`disabled`}</code> attribute
      </td>
    </tr>
    <tr>
      <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Element: </TextBold>
        <Text as='span' fontWeight='900' color={Colors.tw.pink['700']} mdxType="Text">header</Text>
      </Box>
      <td>
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Attribute: </TextBold>
        <code className='whitespace-no-wrap'>{`aria-expanded`}</code>
      </td>
      <td>
        
      </td>
      <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Required: </TextBold>
        <span role="img" aria-label="yes">✅</span>
      </Box>
      <td>
        Each accordion header has an ARIA attribute identifying the state of its corresponding panel:
        <ul>
          <li>
            Expanded: <code>{`aria-expanded="true"`}</code>
          </li>
          <li>
            Collapsed: <code>{`aria-expanded="false"`}</code>
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Element: </TextBold>
        <Text as='span' fontWeight='900' color={Colors.oc.teal[9]} mdxType="Text">panel</Text>
      </Box>
      <td>
      </td>
      <td>
        <code>{`region`}</code>
      </td>
      <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
        <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Required: </TextBold>
        <span role="img" aria-label="no">✖️</span>
      </Box>
      <td>
        Each panel is a <code>{`<section />`}</code> and uses its associated
        accordion header as its label, by using the attribute <code>{`aria-labelledby="<header-id>"`}</code>.
        This can be helpful for screen reader users to perceive the structure of the accordion.
        <Box mt={3} mdxType="Box" />
        <Text fontSize={2} mdxType="Text">
          Note: In some circunstances this is not advisable, as it can lead up to
          a huge number of <code>{`region`}</code> landmarks. If you have more than 6
          accordion items, you should consider using a <code>{`div`}</code> instead.
        </Text>
      </td>
    </tr>
  </tbody>
    </table>
    <Box mt={8} mdxType="Box" />
    <h2>{`Live Demo`}</h2>
    <Box mt={4} mdxType="Box" />
    <p>{`Following the keyboard and ARIA expectations mentioned below, the complete accordion
widget can be implemented as follows:`}</p>
    <Box mt={6} mdxType="Box" />
    <SnippetAccordion mdxType="SnippetAccordion" />
    <Box mt={6} mdxType="Box" />
    <p>{`Let's break down the demo into parts, and see how and why we use each attribute.`}</p>
    <Box mt={4} mdxType="Box" />
    <h3>{`Step 1: Markup structure`}</h3>
    <Box mt={4} mdxType="Box" />
    <p>{`The starting HTML structure of your accordion depends on the context where you use it.
Next, we'll see some common contexts where accordions can be used and how to structure
your HTML accordingly.`}</p>
    <Box mt={8} mdxType="Box" />
    <Box ml={[0, 0, 8, 8]} mdxType="Box">
  <Box display='flex' alignItems='center' mx={-5} my={5} maxWidth='copy' mdxType="Box">
    <Box flex='1 1 auto' px={5} mdxType="Box">
      <ImageStaticFluid path='illustrations/patterns/aria/accordion/accordion-section.png' alt='Illustration of sections of content' mdxType="ImageStaticFluid" />
    </Box>
      
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={30} height={30}>
      <path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z" fill={Colors.oc.blue[9]} />
    </svg>
    <Box flex='1 1 auto' px={5} mdxType="Box">
      <ImageStaticFluid path='illustrations/patterns/aria/accordion/accordion-section-with.png' alt='Illustration of sections of content using the accordion pattern' mdxType="ImageStaticFluid" />
    </Box>
  </Box>
  <Box maxWidth='copy' mdxType="Box">
    <TextBold as='h4' fontSize={3} mdxType="TextBold">Sections of Content</TextBold>
    <Text as='p' fontSize={3} mdxType="Text">
      Whenever you want to use an accordion in a sequence of sections of content, your markup can look like follows:
    </Text>
  </Box>
  <Box display="flex" my={4} mdxType="Box">
    <Code codeString={`<!-- Header -->
<h3>
  <button>Item 1</button>
</h3>
<!-- Panel -->
<section>
  <p>Lorem ipsum dolor sit amet ...</p>
</section>`} language="html" mdxType="Code" />
  </Box>
  <Box maxWidth='copy' mdxType="Box">
    <TextBold as='h5' fontSize={3} color={Colors.tw.pink['700']} mdxType="TextBold">Header</TextBold>
    <Text as='p' fontSize={3} mdxType="Text">
      To match the webpage document structure, we should pick the correspondent
      heading level where the accordion will be placed within the page. This could be
      anything from an <code>{`<h2 />`}</code> to an <code>{`<h6 />`}</code>. In our example, we're using the <code>{`<h3 />`}</code> heading.
      </Text>
    <Text as='p' fontSize={3} mdxType="Text">
      Another aspect of the accordion header, since it's also an interactible element, is that we should use a <code>{`<button>`}</code> element, making it available to use not only by mouse users, but also by keyboard users.
    </Text>
    
    {
          /* Panel  */
        }
    <Box mt={6} mdxType="Box" />
    <TextBold as='h6' fontSize={3} color={Colors.oc.teal[9]} mdxType="TextBold">Panel</TextBold>
    <Text as='p' fontSize={3} mdxType="Text">
      As for the accordion panel, we can make use of <code>{`<section />`}</code> attribute, which is also a <code>{`region`}</code> landmark. This makes the accordion panel appear in screen readers that support navigation by landmarks.
    </Text>
    <Text as='p' fontSize={2} mt={2} mdxType="Text">
      Note: in some circunstances this is not advisable, as it can lead up to a huge number of region landmarks. If you have more than 6 accordion items, you should consider using a div instead.
    </Text>
  </Box>
    </Box>
    <Box mt={10} mdxType="Box" />
    <Box ml={[0, 0, 8, 8]} mdxType="Box">
  <Box display='flex' alignItems='center' mx={-5} my={5} maxWidth='copy' mdxType="Box">
    <Box flex='1 1 auto' px={5} mdxType="Box">
      <ImageStaticFluid path='illustrations/patterns/aria/accordion/accordion-definition.png' alt='Illustration of a definition list' mdxType="ImageStaticFluid" />
    </Box>
      
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={30} height={30}>
      <path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z" fill={Colors.oc.blue[9]} />
    </svg>
    <Box flex='1 1 auto' px={5} mdxType="Box">
      <ImageStaticFluid path='illustrations/patterns/aria/accordion/accordion-definition-with.png' alt='Illustration of a definition list using the accordion pattern' mdxType="ImageStaticFluid" />
    </Box>
  </Box>
  <Box maxWidth='copy' mdxType="Box">
    <TextBold as='h4' fontSize={3} mdxType="TextBold">Definition Lists</TextBold>
    <Text as='p' fontSize={3} mdxType="Text">
      It's often common to use the accordion pattern within a definition list. An example of this would be an FAQ within a page, as the example below shows.
    </Text>
    <Text as='p' fontSize={3} mdxType="Text">
      In this case, we should use the <code>{`<dt>`}</code> for the header and the <code>{`<dd>`}</code> for the panel instead.
    </Text>
  </Box>
  <Box display="flex" mt={4} mdxType="Box">
    <Code codeString={`<dt>
  <dt><!-- Header -->
    <button>Is this an FAQ question?</button>
  </dt>
  <dd><!-- Panel -->
    Yes, it is.
  </dd>
  <dt>
    <button>What about this one?</button>
  </dt>
  <dd>
    Yap, this too.
  </dd>
  <!-- ... more questions ... -->
</dl>`} language="html" mdxType="Code" />
  </Box>
    </Box>
    <Box mt={10} mdxType="Box" />
    <Box ml={[0, 0, 8, 8]} mdxType="Box">
  <Box display='flex' alignItems='center' mx={-5} my={5} maxWidth='copy' mdxType="Box">
    <Box flex='1 1 auto' px={5} mdxType="Box">
      <ImageStaticFluid path='illustrations/patterns/aria/accordion/accordion-fields.png' alt='Illustration of field groups' mdxType="ImageStaticFluid" />
    </Box>
      
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={30} height={30}>
      <path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z" fill={Colors.oc.blue[9]} />
    </svg>
    <Box flex='1 1 auto' px={5} mdxType="Box">
      <ImageStaticFluid path='illustrations/patterns/aria/accordion/accordion-fields-with.png' alt='Illustration of field groups using the accordion pattern' mdxType="ImageStaticFluid" />
    </Box>
  </Box>
  <Box maxWidth='copy' mdxType="Box">
    <TextBold as='h4' fontSize={3} mdxType="TextBold">Field Groups</TextBold>
    <Text as='p' fontSize={3} mdxType="Text">
      Another common use of the accordion component, is within a group of checkbox fields. Amazon uses this in it's faceted search, where users can filter products with certain properties, such as brand, as the example below shows.
    </Text>
    <Text as='p' fontSize={3} mdxType="Text">
      In this case, we should use the <code>{`<legend>`}</code> for the header and use a wrapping elmenent (such as a <code>{`<div>`}</code>) for the panel instead.
    </Text>
  </Box>
  <Box display="flex" mt={4} mdxType="Box">
    <Code codeString={`<fieldset>
  <!-- Header -->
  <legend>
    <button>Filter by Brand</button>    
  </legend>
  <!-- Panel -->
  <div>
    <div>
      <input id="apple" name="brand" type="checkbox" />
      <label for="apple">Apple</label>
    </div>
    <div>
      <input id="motorola" name="brand" type="checkbox" />
      <label for="motorola">Motorola</label>
    </div>
  </div>
  <!-- more brands... -->
</fieldset>`} language="html" mdxType="Code" />
  </Box>
    </Box>
    <Box mt={10} mdxType="Box" />
    <Box ml={[0, 0, 8, 8]} mdxType="Box">
  <Box maxWidth='copy' mdxType="Box">
    <TextBold as='h4' fontSize={3} mdxType="TextBold">Mark icon as decorative</TextBold>
    <Text as='p' fontSize={3} mdxType="Text">
      Most accordions, including the one in our example, have an icon in their header that help users
      visualize the state of the accordion. As this icon is just an extension of the accordion state (which will talk more later on), we should
      mark this icon as a decorative icon, hiding it from assistive technology users.
    </Text>
    <Text as='p' fontSize={3} mdxType="Text">
      To do this, we can add <code>{`aria-hidden="true`}</code> attribute to the icon as we show below.
    </Text>
  </Box>
  <Box mt={4} display="flex" mdxType="Box">
    <Code codeString={`<svg aria-hidden="true">...</svg>`} language="html" mdxType="Code" />
  </Box>
    </Box>
    <Box mt={6} mdxType="Box" />
    <p>{`For the rest of this guide, we will use the accordion pattern in the context of sections of content`}</p>
    <Box mt={6} mdxType="Box" />
    <h3>{`Step 2: Relationships`}</h3>
    <Box mt={4} mdxType="Box" />
    <p>{`The next thing we need to do, is to mark the relationship between the accordion header and panel, as follows:`}</p>
    <Box mt={6} mdxType="Box" />
    <Box ml={[0, 0, 8, 8]} mdxType="Box">
  <Box maxWidth='copy' mdxType="Box">
    <TextBold as='h4' fontSize={3} mdxType="TextBold">Assign unique id's</TextBold>
    <Text as='p' fontSize={3} mdxType="Text">
      To make the accordion header and panel "identifiable" when assigning the relationships between them,
    we first need to assign unique id's to both of them.
    </Text>
  </Box>
  <Box mt={4} display="flex" mdxType="Box">
    <Code codeString={`<h3>
  <button id="accordion-header-1">
    Item 1
  </button>
</h3>
<section id="accordion-panel-1">
  <p>Lorem ipsum dolor sit amet ...</p>
</section>`} language="html" mdxType="Code" />
  </Box>
    </Box>
    <Box mt={8} mdxType="Box" />
    <Box ml={[0, 0, 8, 8]} mdxType="Box">
  <Box maxWidth='copy' mdxType="Box">
    <TextBold as='h4' fontSize={3} mdxType="TextBold">Relationship: <TextBold as='span' fontSize={3} color={Colors.tw.pink['700']} mdxType="TextBold">Header</TextBold> "controls" the <TextBold as='span' fontSize={3} color={Colors.oc.teal[9]} mdxType="TextBold">Panel</TextBold></TextBold>
    <Text as='p' fontSize={3} mdxType="Text">
      The accordion header controls the state of the accordion panel. To mark this relationship, we can make use of the <code>{`aria-controls`}</code> attribute, which value is the id of the accordion panel (in this case, <code>accordion-panel-1</code>).
    </Text>
  </Box>
  <Box mt={4} display="flex" mdxType="Box">
    <Code codeString={`<h3>
  <button id="accordion-header-1"
          aria-controls="accordion-panel-1">
    Item 1
    <svg aria-hidden="true">...</svg>
  </button>
</h3>
<section id="accordion-panel-1">
  <p>Lorem ipsum dolor sit amet ...</p>
</section>`} language="html" mdxType="Code" />
  </Box>
  <Box mt={4} mdxType="Box" />
  <Note title='aria-controls screen reader support' headingLevel='h5' mdxType="Note">
    <Text mdxType="Text">The support for <code>{`aria-controls`}</code> is <LinkAnchor href='https://a11ysupport.io/tech/aria/aria-controls_attribute' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">very limited</LinkAnchor> (only JAWS supports it, to this date). But since the accordion panel immediately follows the accordion header, in this pattern, this is not a big issue as screen reader users will move to the panel as they move down the page.</Text>
  </Note>
    </Box>
    <Box mt={8} mdxType="Box" />
    <Box ml={[0, 0, 8, 8]} mdxType="Box">
  <Box maxWidth='copy' mdxType="Box">
    <TextBold as='h4' fontSize={3} mdxType="TextBold">Relationship: <TextBold as='span' fontSize={3} color={Colors.oc.teal[9]} mdxType="TextBold">Panel</TextBold> is "labelled" by the <TextBold as='span' fontSize={3} color={Colors.tw.pink['700']} mdxType="TextBold">Header</TextBold></TextBold>
    <Text as='p' fontSize={3} mdxType="Text">
      As we're using a <code>{`<section />`}</code> as the accordion panel,
      we can make use of the panel header as the label of this element. This will make sure
      it is correctly identifiable in screen readers that support navigation by landmark.
    </Text>
    <Text as='p' fontSize={3} mdxType="Text">
      To achieve this, we can use the <code>{`aria-labelledby`}</code> attribute on the accordion panel, which value should be the id of the corresponding accordion header.
    </Text>
  </Box>
  <Box mt={4} display="flex" mdxType="Box">
    <Code codeString={`<h3>
  <button id="accordion-header-1"
          aria-controls="accordion-panel-1">
    Item 1
    <svg aria-hidden="true">...</svg>
  </button>
</h3>
<section id="accordion-panel-1"
         aria-labelledby="accordion-header-1">
  <p>Lorem ipsum dolor sit amet ...</p>
</section>`} language="html" mdxType="Code" />
  </Box>
    </Box>
    <Box mt={10} mdxType="Box" />
    <h3>{`Step 3: State`}</h3>
    <Box mt={4} mdxType="Box" />
    <p>{`The last thing to do, is to have the accordion state (opened vs. closed) being communicated
to screen reader users.`}</p>
    <Box mt={6} mdxType="Box" />
    <Box ml={[0, 0, 8, 8]} mdxType="Box">
  <Box maxWidth='copy' mdxType="Box">
    <TextBold as='h4' fontSize={3} mdxType="TextBold">Opened State</TextBold>
    <Text as='p' fontSize={3} mdxType="Text">
      To mark the accordion item as opened, we can use the <code>aria-expanded</code> attribute on the accordion header and set its value to <code>true</code>.
    </Text>
  </Box>
  <Box mt={4} display="flex" mdxType="Box">
    <Code codeString={`<h3>
  <button id="accordion-header-1"
          aria-controls="accordion-panel-1"
          aria-expanded="true">
    Item 1
    <svg aria-hidden="true">...</svg>
  </button>
</h3>
<section id="accordion-panel-1"
         aria-labelledby="accordion-header-1">
  <p>Lorem ipsum dolor sit amet ...</p>
</section>`} language="html" mdxType="Code" />
  </Box>
    </Box>
    <Box mt={8} mdxType="Box" />
    <Box ml={[0, 0, 8, 8]} mdxType="Box">
  <Box maxWidth='copy' mdxType="Box">
    <TextBold as='h4' fontSize={3} mdxType="TextBold">Closed State</TextBold>
    <Text as='p' fontSize={3} mdxType="Text">
      To mark the accordion item as closed, we can use the <code>aria-expanded</code> attribute on the accordion header and set its value to <code>false</code>.
    </Text>
    <Text as='p' fontSize={3} mdxType="Text">
      This is not enough for the closed state. We also need to prevent the accordion panel from being reached by both keyboard and screen reader users. One of the ways to do this is to use the attribute <code>hidden</code> on the accordion panel, which not only hides the panel visually but also makes it unreachable to keyboard and screen reader users.
    </Text>
  </Box>
  <Box mt={4} display="flex" mdxType="Box">
    <Code codeString={`<h3>
  <button id="accordion-header-1"
          aria-controls="accordion-panel-1"
          aria-expanded="false">
    Item 1
    <svg aria-hidden="true">...</svg>
  </button>
</h3>
<section id="accordion-panel-1"
         aria-labelledby="accordion-header-1"
         hidden>
  <p>Lorem ipsum dolor sit amet ...</p>
</section>`} language="html" mdxType="Code" />
  </Box>
    </Box>
    <Box mt={8} mdxType="Box" />
    <Note title='Note: How to correctly hide collapsed accordion items' headingLevel='h4' labelProps={{
      fontSize: 4
    }} mdxType="Note">
  <Text mdxType="Text">
    A common mistake we see when an accordion panel is collapsed, it that its panel is still
    accessed by screen reader users.
  </Text>
  <Text mdxType="Text">
    The underlying reason for this comes from the technique used to hide content,
    as some techniques might still **make the panel accessible by keyboard users or
    screen reader users**.
  </Text>
  <Text mdxType="Text">
    If you are not sure what's the best way to hide content to everyone, the following
    table list different techniques to hide content.
  </Text>
  <Box mt={6} mdxType="Box" />
  <table aria-labelledby='aria-roles-states-and-properties'>
    <thead>
      <tr>
        <th>CSS</th>
        <th>HTML</th>
        <th>Screen</th>
        <th>Reader</th>
        <th>Keyboard</th>
        <th>Animatable</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">CSS: </TextBold>
          <code className='whitespace-no-wrap'>{`height: 0`}</code>
        </td>
        <td></td>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Screen: </TextBold>
          <span role="img" aria-label="yes">✅</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Screen Readers: </TextBold>
          <span role="img" aria-label="no">✖️</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Keyboard: </TextBold>
          <span role="img" aria-label="no">✖️</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'left']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Animatable: </TextBold>        
          <Box display='flex' alignItems='center' mdxType="Box">
            <span role="img" aria-label="warning">⚠️</span>
            <Box ml={1} mdxType="Box" />
            <sup className='md-footnote'><a href="#animation-warning"><span className='visually-hidden'>Footnote </span>*</a></sup>
          </Box>
        </Box>
      </tr>
      <tr>
        <td>
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">CSS: </TextBold>
          <code className='whitespace-no-wrap'>{`max-height: 0`}</code>
        </td>
        <td></td>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Screen: </TextBold>
          <span role="img" aria-label="yes">✅</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Screen Readers: </TextBold>
          <span role="img" aria-label="no">✖️</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Keyboard: </TextBold>
          <span role="img" aria-label="no">✖️</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'left']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Animatable: </TextBold>
          <Box display='flex' alignItems='center' mdxType="Box">
            <span role="img" aria-label="warning">⚠️</span>
            <Box ml={1} mdxType="Box" />
            <sup className='md-footnote'><a href="#animation-warning"><span className='visually-hidden'>Footnote </span>*</a></sup>
          </Box>
        </Box>
      </tr>
      <tr>
        <td>
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">CSS: </TextBold>
          <code className='whitespace-no-wrap'>{`transform: scale(0)`}</code>
        </td>
        <td></td>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Screen: </TextBold>
          <span role="img" aria-label="yes">✅</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Screen Readers: </TextBold>
          <span role="img" aria-label="no">✖️</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Keyboard: </TextBold>
          <span role="img" aria-label="no">✖️</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'left']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Animatable: </TextBold>
          <span role="img" aria-label="yes">✅</span>
        </Box>
      </tr>
      <tr>
        <td>
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">CSS: </TextBold>
          <code className='whitespace-no-wrap'>{`visibility: hidden`}</code>
        </td>
        <td></td>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Screen: </TextBold>
          <span role="img" aria-label="yes">✅</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Screen Readers: </TextBold>
          <span role="img" aria-label="yes">✅</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Keyboard: </TextBold>
          <span role="img" aria-label="yes">✅</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'left']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Animatable: </TextBold>
          <Box display='flex' alignItems='center' mdxType="Box">
            <span role="img" aria-label="warning">⚠️</span>
            <Box ml={1} mdxType="Box" />
            <sup className='md-footnote'><a href="#animation-warning"><span className='visually-hidden'>Footnote </span>*</a></sup>
          </Box>
        </Box>
      </tr>
      <tr>
        <td>
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">CSS: </TextBold>
          <code>{`display: none`}</code>
        </td>
        <td>
        </td>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Screen: </TextBold>
          <span role="img" aria-label="yes">✅</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Screen Readers: </TextBold>
          <span role="img" aria-label="yes">✅</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Keyboard: </TextBold>
          <span role="img" aria-label="yes">✅</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'left']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Animatable: </TextBold>
          <span role="img" aria-label="no">✖️</span>
        </Box>
      </tr>
      <tr>
        <td>
          
        </td>
        <td>
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">HTML: </TextBold>
          <code>{`hidden`}</code>
        </td>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Screen: </TextBold>
          <span role="img" aria-label="yes">✅</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Screen Readers: </TextBold>
          <span role="img" aria-label="yes">✅</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Keyboard: </TextBold>
          <span role="img" aria-label="yes">✅</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'left']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Animatable: </TextBold>
          <span role="img" aria-label="no">✖️</span>
        </Box>
      </tr>
      <tr>
        <td>
          
        </td>
        <td>
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">HTML: </TextBold>
          <code css={{
                whiteSpace: 'nowrap'
              }}>{`aria-hidden="true"`}</code>
        </td>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Screen: </TextBold>
          <span role="img" aria-label="no">✖️</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Screen Readers: </TextBold>
          <span role="img" aria-label="yes">✅</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Keyboard: </TextBold>
          <span role="img" aria-label="no">✖️</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'left']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Animatable: </TextBold>
          <span role="img" aria-label="no">✖️</span>
        </Box>
      </tr>
      <tr>
        <td>
          
        </td>
        <td>
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">HTML: </TextBold>
          <code>{`inert`}</code>
        </td>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Screen: </TextBold>
          <span role="img" aria-label="no">✖️</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Screen Readers: </TextBold>
          <span role="img" aria-label="yes">✅</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'center']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Keyboard: </TextBold>
          <span role="img" aria-label="yes">✅</span>
        </Box>
        <Box as='td' textAlign={['left', 'left', 'left']} mdxType="Box">
          <TextBold display={['inline', 'inline', 'none']} mdxType="TextBold">Animatable: </TextBold>
          <span role="img" aria-label="no">✖️</span>
        </Box>
      </tr>
    </tbody>
  </table>
  <Box pt={9} id='animation-warning' mdxType="Box" />
  <Box className='no-auto-vr' mdxType="Box">
    <Label as='h4' mdxType="Label">
      <span role="img" aria-label="warning">⚠️</span> CSS Animations&nbsp;
      <sup className='md-footnote'><span className='visually-hidden'>Footnote </span>*</sup>
    </Label>
    <List mdxType="List">
      <ListItem mt={3} mdxType="ListItem">
        <Box display='flex' alignItems='baseline' mdxType="Box">
          <Box width={1 / 3} mdxType="Box">
            <TextBold mdxType="TextBold"><code>{`height`}</code> and <code>{`max-height`}</code>: </TextBold>
          </Box>
          <Box width={2 / 3} mdxType="Box">
            <Text mdxType="Text">while it's technically possible to animate these properties, it results in <LinkAnchor href='https://www.html5rocks.com/en/tutorials/speed/high-performance-animations/' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">non-performant animations</LinkAnchor> since they cause a layout recalculation at every frame.</Text>
          </Box>
        </Box>
        
        
      </ListItem>
      <ListItem mt={7} mdxType="ListItem">
        <Box display='flex' mdxType="Box">
          <Box width={1 / 3} mdxType="Box">
            <TextBold mdxType="TextBold"><code>{`visibility`}</code>:</TextBold>
          </Box>
          <Box width={2 / 3} mdxType="Box">
            <Text mdxType="Text">
                Can't be animated.
            </Text>
            <Text mt={2} mdxType="Text">
              CSS transitions work by calculating intermediate amounts between two values. As <code>{`visibility`}</code> changes between <code>{`visible`}</code>/<code>{`hidden`}</code>, there are no possible intermediate values to extrapolate. 
            </Text>
            <Text mt={2} mdxType="Text">
              Some <LinkAnchor href="https://greywyvern.com/?post=337" target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">"hacks"</LinkAnchor> allow CSS transitions using <code>{`visiblity`}</code> and <code>{`opacity`}</code> using <code>{`transition-delay`}</code>, but this technique is better suited for fade-in/fade-out effects rather tha opening/collapsing effects (which is most likely the effect you're aiming for an accordion).
            </Text>
          </Box>
        </Box>
      </ListItem>
    </List>
    
    
  </Box>
  <Box mt={10} mdxType="Box" />
  <SnippetAccordionFlowchart mdxType="SnippetAccordionFlowchart" />
    </Note>
    <Box mt={8} mdxType="Box" />
    <h2>{`Implementations of the Accordion Pattern`}</h2>
    <Box mt={4} mdxType="Box" />
    <p>{`Implementing an accordion in your framework (or no framework) can be a daunting task. Below we show simple implementations of the accordion pattern in several frameworks.`}</p>
    <p>{`In all these examples, we're using the accordion pattern in the context of sections of content. As mentioned above, you should `}<LinkGatsby to="./#animation-warning" mdxType="LinkGatsby">{`adapt the header and panel markup to match your context`}</LinkGatsby>{`.`}</p>
    <Box mt={6} mdxType="Box" />
    <h3>{`Vanilla JS`}</h3>
    <Box mt={4} mdxType="Box" />
    <Box mdxType="Box">
  <Box mdxType="Box">
    <Label as='span' mdxType="Label">Javascript</Label>
    <Code codeString={`const accordionHeaders = document.querySelectorAll('data-accordion-header');\n
Array.prototype.forEach.call(accordionHeaders, accordionHeader => {
  let target = accordionHeader.parentElement.nextElementSibling;\n
  accordionHeader.onclick = () => {
    let expanded = accordionHeader.getAttribute('aria-expanded') === 'true' || false;
    accordionHeader.setAttribute('aria-expanded', !expanded);
    target.hidden = expanded;
  }
})`} language='javascript' mdxType="Code" />
  </Box>
  <Box mt={3} mdxType="Box">
    <Label as='span' mdxType="Label">HTML</Label>
    <Code codeString={`<h3>
  <button id="accordion-header-1"
          aria-expanded="false"
          aria-controls="accordion-panel-1"
          data-accordion-header>
    Item 1
    <svg aria-hidden="true">...</svg>
  </button>
</h3>
<section id="accordion-panel-1"
          aria-labelledby="accordion-header-1"
          hidden>
  <p>Lorem ipsum dolor sit amet...</p>
</section>`} language='html' mdxType="Code" />
  </Box>
    </Box>
    <Box mt={6} mdxType="Box" />
    <h3>{`React`}</h3>
    <Box mt={4} mdxType="Box" />
    <Box mdxType="Box">
  <Box mdxType="Box">
    <Label as='span' mdxType="Label">Component</Label>
    <Code codeString={`import { useState } from 'react';\n
function AccordionItem({ id, title, children }) {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <>
      <h3>
        <button
          id={\`\${id}-header\`}
          aria-controls={\`\${id}-panel\`}
          aria-expanded={isExpanded}
        >{title}</button>
      </h3>
      <section
        id={\`\${id}-panel\`}
        aria-labelledby={\`\${id}-header\`}
        hidden={!isExpanded}
      ></section>
    </>
  )
}`} language='jsx' mdxType="Code" />
  </Box>
  <Box mt={3} mdxType="Box">
    <Label as='span' mdxType="Label">Usage</Label>
    <Code codeString={`<AccordionItem id='item-1' title='Section title'>
  <p>Lorem ipsum dolor sit amet...</p>
</AccordionItem>`} language='jsx' mdxType="Code" />
  </Box>
    </Box>
    <h3>{`Vue.js`}</h3>
    <Box mt={4} mdxType="Box" />
    <Box mdxType="Box">
  <Box mdxType="Box">
    <Label as='span' mdxType="Label">Component</Label>
    <Code codeString={`<template>
  <h3>
    <button
      :id="id + '-header'"
      :aria-controls="id = '-panel'"
      :aria-expanded="isExpanded">
      {{title}}
    </button>
  </h3>
  <section
    :id="id + '-panel'"
    :aria-labelledby="id + '-header'">
    <slot></slot>
  </section>
</template>\n
<script>
export default {
  name: "AccortionItem",
  props: {
    id: { type: String, required: true },
    title: { type: String, required: true }
  },
  data: {
    isExpanded: false
  },
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded;
    }
  }
};
</script>`} language='javascript' mdxType="Code" />
  </Box>
  <Box mt={3} mdxType="Box">
    <Label as='span' mdxType="Label">Usage</Label>
    <Code codeString={`<accordion-item title="My Section" id="item-1>
  <p>Lorem ipsum dolor sit amet...</p>
</accordion-item>`} language='jsx' mdxType="Code" />
  </Box>
    </Box>
    <h2>{`Related WCAG Success Criterion`}</h2>
    <Box mt={4} mdxType="Box" />
    <nav aria-labelledby='related-wcag-success-criterion'>
  <ul>
    <li>
      <LinkAnchor href='https://www.w3.org/TR/UNDERSTANDING-WCAG20/keyboard-operation-keyboard-operable.html' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">
        2.1.1: Keyboard (Level A)
      </LinkAnchor>
    </li>  
    <li>
      <LinkAnchor href='https://www.w3.org/WAI/WCAG21/Understanding/name-role-value.html' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">
        4.1.2: Name, Role, Value (Level A)
      </LinkAnchor>
    </li>  
  </ul>
    </nav>
    <h2>{`Related Resources`}</h2>
    <Box mt={4} mdxType="Box" />
    <p>{`Lots of smart folks wrote about this before and their articles were incredibly helpful. You should definetly read them as well.`}</p>
    <Box mt={4} mdxType="Box" />
    <nav aria-labelledby='related-resources'>
  <ul>
    <li>
      <LinkAnchor href='https://www.sarasoueidan.com/blog/accordion-markup/' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">
        How do you mark up an accordion?
      </LinkAnchor> by Sara Soueidan:
    </li>  
    <li>
      <LinkAnchor href='https://www.scottohara.me/blog/2017/10/25/accordion-release.html' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">
        Accessible ARIA Accordions
      </LinkAnchor> by Scott O’Hara
    </li>  
    <li>
      <LinkAnchor href='https://inclusive-components.design/tabbed-interfaces/' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">
        Collapsible Sections
      </LinkAnchor> by Heydon Pickering
    </li>  
  </ul>
    </nav>
    {
      /* ## Libraries
      <Box mt={4} />
      Some search engines also recognise this pattern and display results with this information, as shown below:
      
      <Box mt={4} />
      Some search engines also recognise this pattern and display results with this information, as shown below:
      ### React
      <Box mt={4} />
      Some search engines also recognise this pattern and display results with this information, as shown below:
      ### Angular
      <Box mt={4} />
      Some search engines also recognise this pattern and display results with this information, as shown below:
      ### VueJS
      */
    }


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      